import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { InView } from "react-intersection-observer";
import "../style/Posts.css";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function loadPosts() {
      const response = await fetch(
        "https://wp.friafolket.se/wp-json/wp/v2/posts?per_page=100"
      );
      if (!response.ok) {
        return;
      }

      const posts = await response.json();
      setPosts(posts);
    }

    loadPosts();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    image: {
      maxWidth: "100%",
    },
  }));

  const classes = useStyles();

  return (
    <Container maxWidth="lg" className="postContainer">
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.gridItems}>
          {posts.map((post, index) => (
            <InView key={index}>
              {({ inView, ref, entry }) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  key={index}
                  className={[
                    inView ? "inView" : "",
                    post.acf.media && post.acf.media.includes(".mp4")
                      ? "video"
                      : "",
                  ].join(" ")}
                  ref={ref}
                >
                  {post.acf.media && post.acf.media.includes(".mp4") ? (
                    <video
                      width="400"
                      height="300"
                      muted
                      onMouseOver={(event) => event.target.play()}
                      onMouseOut={(event) => event.target.pause()}
                    >
                      <source src={post.acf.media} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      className={classes.image}
                      src={post.acf.media}
                      alt={post.acf.caption}
                    ></img>
                  )}

                  <div className="caption">
                    <p>
                      {post.acf.caption} - {inView}
                    </p>
                  </div>
                </Grid>
              )}
            </InView>
          ))}
        </Grid>
      </div>
    </Container>
  );
};

export default Posts;
