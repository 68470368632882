import "../style/header.css";
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import logo from "../images/logo.jpg";
import { ScrollTo } from "react-scroll-to";

const Header = () => {
  const [intro, setIntro] = useState([]);

  useEffect(() => {
    async function loadPosts() {
      const response = await fetch(
        "https://wp.friafolket.se/wp-json/wp/v2/pages?slug=home"
      );
      if (!response.ok) {
        return;
      }

      const page = await response.json();
      setIntro(page);
    }

    loadPosts();
  }, []);

  const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      padding: theme.spacing(6, 0, 6),
    },
    heroButtom: {
      margin: theme.spacing(4, 0, 2, 0),
      textDecoration: "underline",
      cursor: "pointer",
    },
    inverted: {
      textDecoration: "underline",
    },
    logo: {
      width: "140px",
      borderRadius: "50%",
      border: "1px solid #dedede",
      marginBottom: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#cccccc",
      },
      secondary: {
        main: "#ccc",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <header>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <img src={logo} className={classes.logo} alt="Logo"></img>
            <Typography variant="h5" align="center" color="inherit" paragraph>
              {intro.map((post, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                ></div>
              ))}
            </Typography>
            <ScrollTo>
              {({ scroll }) => (
                <span
                  className={classes.heroButtom}
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    scroll({
                      x: 0,
                      y: document.body.scrollHeight,
                      smooth: true,
                    })
                  }
                >
                  Kontakt
                </span>
              )}
            </ScrollTo>
          </Container>
        </div>
      </header>
    </ThemeProvider>
  );
};

export default Header;
